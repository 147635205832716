<template>
    <a target="_blank" :href="malLink" class="media-item">
        <div class="item-cover">
            <img v-if="!imageNotFound" v-lazy="item.MALimageLink" class="item-cover-image" @error="error">
            <div class="no-image-message" v-if="imageNotFound">
                <div>
                    Image
                </div>
                <div>
                    Not Found.
                </div>
            </div>
            <div class="user-rating" v-if="item.rawScore">
                {{item.rawScore}}
            </div>
        </div>
        <div class="item-info" >
            <div class="title">
                {{formattedTitle}}
            </div>
            <div v-if="formattedAltTitle" class="alt-title">
                {{ formattedAltTitle }}
            </div>
            <div v-if="formattedSeason" class="season">{{ formattedSeason }}</div>
            <div class="genre-list">{{genreString}}</div>
        </div>
    </a>
</template>

<script>
export default {
    name: 'MediaItem',
    props: ['item'],
    data () {
        return {
            imageNotFound: false
        }
    },
    computed: {
        malLink () {
            if (this.item.MALid) {
                return `http://www.myanimelist.net/${this.item.type}/${this.item.MALid}`;
            }

            return '';
        },
        formattedTitle () {
            if (!this.item.title) return '';

            return this.unicodeFilter(this.item.title);
        },
        formattedAltTitle () {
            if (!this.item.alternateTitle) return '';

            return this.unicodeFilter(this.item.alternateTitle);
        },
        genreString () {
            if (!this.item.genres) return '';

            return this.item.genres.map(genre => genre.name).join(', ');
        },
        formattedSeason () {
            if(!this.item.season || this.item.season === '?') return null;

            return this.item.season;
        }
    },
    methods: {
        unicodeFilter (input) {
            return input.replace(/\\u[\dABCDEFabcdef][\dABCDEFabcdef][\dABCDEFabcdef][\dABCDEFabcdef]/g,
                match => String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16)));
        },
        error () {
            this.imageNotFound = true;
        }
    },
};
</script>

<style scoped lang="scss">
@import "../../../mixins/all.scss";

.media-item {
    box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.26);
    cursor: pointer;
    display: flex;
    height: 250px;
    overflow: hidden;
    position: relative;
    text-decoration: none;

    &:hover {
        .title {
            text-decoration: underline;
        }
    }

    .no-image-message {
        height: 100%;
        width: 100%;
        flex-direction: column;
        font-size: 16px;
        background-color: transparentize($olive-black, 0.9);
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
    }

    .item-cover, .item-cover-image {
        min-width: 175px;
        width: 175px;
        height: 250px;
    }

    .item-info {
        padding: 10px;
        flex-grow: 1;

        .title {
            font-size: 18px;
            font-weight: bold;
        }

        .alt-title {
            font-size: 14px;
        }

        .season {
            font-size: 14px;
            color: rgba($olive-black, 0.5);
        }

        .genre-list {
            margin-top:10px;
            font-size:14px;
            color: $olive-black;
        }
    }

    .item-cover {
        position: relative;

         .user-rating {
            color: white;
            position: absolute;
            right: 10px;
            bottom: -5px;
            margin-top: 170px;
            font-size: 50px;
            text-shadow: 1px 1px 1px black;
            font-weight: bold;
        }
    }

    .item-info {
        align-items: flex-start;
        background-color: rgba(255, 255, 255, 0.15);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .title {
            font-style: italic;
            font-weight: bold;
        }
    }
}

</style>
